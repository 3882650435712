<template>
  <services-merge-list></services-merge-list>
</template>

<script>
import { defineComponent } from 'vue';
import ServicesMergeList from './list/List.vue';
export default defineComponent({
  name: 'ServicesMerge',
  components: { ServicesMergeList },
});
</script>

<style lang="scss"></style>
